<template>
  <div class="page-header">
    <div class="page-header__content">
      <div class="left">
        <img
          v-if="windowWidth > 960"
          :src="BackButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.push({ name: 'Dashboard' })"
        >
        <h4>{{ $t('error-assign-machine.heading') }}</h4>
      </div>
      <div class="right">
        <custom-button
          :fullwidth="windowWidth <= 960 ? true : false"
          @click="$router.push({ name: 'selectUser' })"
        >
          <template v-slot:leftIcon>
            <img
              :src="buttonIcon"
              alt="Plus icon"
            >
          </template>
          {{ $t('error-assign-machine.button') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import BackButtonIcon from '@/assets/arrow_button.svg'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import buttonIcon from '@/assets/icons/add.svg'

export default {
  name: 'ErrorAssignMachineHeader',
  components: {
    CustomButton
  },
  data () {
    return {
      BackButtonIcon,
      buttonIcon,
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 225px;
  background-color: $primary-darkgrey;
  padding: 48px 166px;

  div.page-header__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1108px;
    height: 64px;

    div.left {
      display: flex;
      align-items: center;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }

      h4 {
        color: $primary-white;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.page-header {
    height: 149px;
    padding: 24px;

    div.page-header__content {
      flex-direction: column;
      max-width: 100%;
      justify-content: unset;
      height: unset;

      div.left {
        h4 {
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
