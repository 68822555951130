<template>
  <div class="error-assign-wrapper">
    <error-assign-machine-header />
    <error-assign-machine-table class="users-table" />
  </div>
</template>

<script>
import ErrorAssignMachineHeader from './PageHeader.vue'
import ErrorAssignMachineTable from './ErrorAssignMachineTable/index.vue'

export default {
  name: 'ErrorAssignMachineUsers',
  components: {
    ErrorAssignMachineHeader,
    ErrorAssignMachineTable
  }
}
</script>

<style lang="scss" scoped>
div.error-assign-wrapper {
  width: 100%;
  padding-top: 72px; // because of header

  .users-table {
    margin-top: -48px;
    width: 100%;
  }
}

@media screen and (max-width: $mobile) {
  div.error-assign-wrapper {
    padding: 64px 0 100px 0;

    .users-table {
      margin-top: 0;
    }
  }
}
</style>
